<template>
  <div>
    <solution-header
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
        :change="change"
    />
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`产品优势`" :en_title="`Product Advantages`"></Paragraph>
    <!--    <ImageList :images="images"></ImageList>-->
    <Titlestrlist :datalist="datalist" style="margin-left: 15%;"></Titlestrlist>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`产品特点`" :en_title="`Features`"></Paragraph>
    <Titlestrlist :datalist="datalist1" style="margin-left: 15%;"></Titlestrlist>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`应用场景`" :en_title="`Application Scenarios`"></Paragraph>
    <div style="margin-left: 10%;margin-right: 10%;
    display: flex;flex-direction: column;align-items: center;margin-top: 3rem">
    <Lettertitleblock :datalist="datalistx"
                      style="display: flex;
                      flex-direction: column;align-items: center"></Lettertitleblock>
    </div>
  </div>
</template>

<script>
import SolutionHeader from '@/components/common/SolutionHeader';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';

export default {
  name: 'BaaSLeaflet',
  components: {
    // ImageList,
    SolutionHeader,
    Paragraph,
    Titlestrlist,
    Lettertitleblock,
  },
  data() {
    return {
      datalistx: [
        {
          title: '联合溯源',
          imgsrc: 'A',
          list: [
            '单链溯源受限于联盟的参与机构',
            '很难达到商品全生命周期追踪',
            '如何保证在跨链传递中不丢失链上数据真实性',
            '为厂家及终端客户提供商品的',
          ],
        }, {
          title: '跨链可信取证',
          imgsrc: 'B',
          list: [
            '可信存证是目前大量落地推广的区块链场景',
            '但是每条链也形成了数据的孤岛',
            '通过应用层跨链，很难保证信息的真实性',
            '同时应用层适配异构链的改造成本也很高',
            '如何能够低成本地进行信息可信跨链传递',
            '成为迫切需要解决的问题',
          ],
        }, {
          title: '票据可信流转',
          imgsrc: 'C',
          list: [
            '区块链在供应链场景中也有大量落地',
            '供应商的票据上链存证',
            '为供应商积累信用',
            '如何将供应商的链上票据信息',
            '可信传递到金融机构的区块链上',
            '为供应商融资增信成为当前的诉求',
          ],
        },
      ],
      datalist: [
        {
          title: '低延时跨链',
          content: '基于硬件安全跨链验证技术，使得跨链端到端延时在毫秒级别。',
        },
        {
          title: '高吞吐流量',
          content: '跨链中继组件性能具备横向扩展能力，能同时处理十万级别的跨链流量。',
        },
        {
          title: '超大规模组网能力',
          content: '路由形态的中继组件，具备自由组网特性，支持上千条区块链同时组网，安全可靠通讯。',
        },
      ],
      datalist1: [
        {
          title: '异构兼容',
          content: '对异构链兼容性友好，支持蚂蚁链、Fisco等类型联盟链、私有链、公链双向互通，促进多机构跨行业的生态融合。',
        },
        {
          title: '互操作性强',
          content: '远程与其他同构/异构区块链进行双向消息通讯，通过叠加业务中间层协议，完成链与链之间的复杂互操作，完成业务复杂规则。',
        },
        {
          title: '高安全、高性能',
          content: '支持TEE、零知识证明等可验证隐私计算，完成对区块链数据的存在性认证，生成认证证明，保障链上数据隐私，降低跨链认证的复杂度，提高通讯效率。',
        },
        {
          title: '便捷接入、高效协同',
          content: '可视化控制台快速注册区块链，便捷的授权流程保护链上数据隐私，标准通用的跨链合约，支持各类跨链场景信任可信传递。',
        },
      ],
      title: '跨链服务',
      body:
          '基于跨链协议的区块链网络生态系统，旨在打通不同的区块链网络，让其具有互操作性，使不同区块链的价值得以自由流转，从而根本上解决区块链的“孤岛效应”。',
      // TODO: REPLACE WITH PRIVACY PANEL IMAGE
      image: require('@/assets/image/chain/basic/crosschain/crosschain.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/basic/crosschain/15_01.jpg'),
        require('@/assets/image/chain/basic/crosschain/15_02.jpg'),
        require('@/assets/image/chain/basic/crosschain/15_03.jpg'),
      ],
      change: 'width: 80%;margin-right:4vw',
    };
  },
};
</script>

<style scoped></style>
